import { computed, action, reaction, IReactionDisposer, observable, toJS, runInAction, autorun } from 'mobx'
import { walletStore } from '@/stores/wallet-store'
import { asyncAction } from 'mobx-utils'
import { every, groupBy, isEmpty, isNumber, map } from 'lodash'
import { FixedNumber } from '@ethersproject/bignumber'
import { web3 } from '@/helpers/bsc-helper'
import { MAX_ACCOUNT_PER_TRANS, Zero } from '@/constants'
import { chunk } from 'lodash-es'
import { blockchainHandler } from '@/blockchainHandlers'
import { SolidityMultiClaimContract } from '@/blockchainHandlers/multiclaim-contract-solidity'
import { bnHelper } from '@/helpers/bignumber-helper'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

export default class AddWinnerViewModel {
  @observable currentStep = 1
  @observable bnbInfo

  @observable codeMirrorText = ''
  @observable isUploadFile = false
  @observable walletEditor: any
  @observable errorList: any = {}
  @observable duplicateList: any = {}
  @observable walletEditorData: any = []
  @observable isEmptyError = false

  @observable transNumber = 0
  @observable totalAmount = Zero
  @observable isApproved = false
  @observable transData: any = []
  @observable estimateGas = Zero

  @observable loadingPage = false
  @observable checkApproveLoading = true
  @observable approveLoading = false
  @observable nextStepLoading = false

  @observable poolId!: any
  @observable pool: any = null
  @observable multiclaimHandler?: SolidityMultiClaimContract

  _disposers: IReactionDisposer[] = []

  constructor() {
    this._disposers = [
      autorun(() => {
        if (walletStore.connected && this.poolId) {
          this.loadData()
        }
      })
    ]
  }
  destroy() {
    this._disposers.forEach(d => d())
  }
  @action changePoolId(id) {
    this.poolId = id
  }
  @action changeNextStepLoading(value) {
    this.nextStepLoading = value
  }

  @asyncAction *loadData() {
    try {
      this.loadingPage = true
      this.multiclaimHandler = blockchainHandler.multiclaimContractFactory({
        chainId: walletStore.chainId
      }) as SolidityMultiClaimContract
      this.multiclaimHandler.injectProvider()
      yield this.multiclaimHandler.init()
      yield this.loadPool()
      if (this.pool) this.bnbInfo = yield this.multiclaimHandler.getBnbInfo(walletStore.account)
    } catch (e) {
      snackController.error('Pool not found')
      //
    } finally {
      this.loadingPage = false
    }
  }

  @asyncAction *loadPool() {
    this.pool = yield this.multiclaimHandler!.fetchClaimPoolDetailOwner(this.poolId, walletStore.account)
  }

  @asyncAction *setCurrentStep(value: number) {
    this.currentStep = value
    if (value === 2) {
      yield this.loadConfirmStep()
    }
  }

  //prepare function
  @action changeIsUploadFile(value) {
    this.isUploadFile = value
  }

  @action changeState(state: any) {
    if (state.walletEditorData) this.walletEditorData = state.walletEditorData
    if (state.walletEditor) this.walletEditor = state.walletEditor
    if (state.estimateGas) this.estimateGas = state.estimateGas
  }

  @action validatePrepareStep() {
    const editorData: any = []
    const errorList: any = {}
    const duplicateList: any = {}
    let i = 0
    let line = 1
    //handle invalid address and wrong amount
    this.walletEditor.eachLine(f => {
      this.walletEditor.removeLineClass(line - 1, 'wrap', 'line-error')
      if (isEmpty(f.text)) {
        line++
        return
      }
      let errorMsg = ''
      let arr = f.text.trim().split(',')
      arr = arr.map(item => item.trim())
      if (arr.length < 2 || !new RegExp(/^\d+(\.\d*)?$/).test(arr[1])) {
        errorMsg = web3.utils.isAddress(arr[0])
          ? 'wrong amount'
          : `${arr[0]} is a invalid wallet address and wrong amount`
      } else if (!web3.utils.isAddress(arr[0])) errorMsg = `${arr[0]} is a invalid wallet address`
      if (!isEmpty(errorMsg)) {
        errorList[line] = errorMsg
        editorData[i] = { text: f.text, isInvalidParam: true }
      } else {
        editorData[i] = {
          text: f.text,
          isInvalidParam: false,
          address: arr[0],
          amount: FixedNumber.from(arr[1]),
          line: line
        }
      }
      i++
      line++
    })
    const validData = editorData.filter(item => !item.isInvalidParam)
    const groupByAddress = Object.values(groupBy(validData, 'address'))
    groupByAddress.map((group: any) => {
      if (group.length > 1) {
        group.map((item, index) => {
          if (index > 0) duplicateList[item.line] = `duplicate address ${item.address}`
        })
      }
    })
    Object.keys(errorList).map(key => {
      this.walletEditor.addLineClass(parseInt(key) - 1, 'wrap', 'line-error')
    })
    Object.keys(duplicateList).map(key => {
      this.walletEditor.addLineClass(parseInt(key) - 1, 'wrap', 'line-error')
    })
    this.errorList = errorList
    this.duplicateList = duplicateList
    this.walletEditorData = editorData
  }

  async handleEstimateGas() {
    const arr = chunk(this.walletEditorData, MAX_ACCOUNT_PER_TRANS)
    try {
      const promises: any = []
      arr.map(async item => {
        const addresses = map(item, 'address')
        const values = map(item, 'amount')
        promises.push(
          this.multiclaimHandler!.estimateGasAddWinnerList(
            walletStore.account,
            this.poolId,
            addresses,
            values,
            this.decimals
          )
        )
      })
      const estimates = await Promise.all(promises)
      const totalEstimates = estimates.reduce((a: FixedNumber, b) => {
        return a.addUnsafe(b as FixedNumber)
      }, Zero)

      return totalEstimates
    } catch (e) {
      console.error(e)
    }
  }

  // eslint-disable-next-line require-yield
  @asyncAction *prepareNextAction() {
    try {
      this.validatePrepareStep()
      if (this.errorCount === 0 && this.duplicateCount === 0) {
        if (this.walletEditorData.length === 0) {
          this.isEmptyError = true
          return false
        } else {
          this.transNumber = Math.ceil(parseFloat(`${this.walletEditorData.length}`) / MAX_ACCOUNT_PER_TRANS)
          this.totalAmount =
            this.walletEditorData.reduce((a, b) => {
              if (a.isZero()) return b.amount
              else return a.addUnsafe(b.amount)
            }, Zero) || Zero
          this.codeMirrorText = this.walletEditor.getValue()
          return true
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  @action changeEmptyError() {
    this.isEmptyError = false
  }

  async deleteLines() {
    this.validatePrepareStep()
    const finalData = this.walletEditorData.filter(item => !item.isInvalidParam)
    const convertedString = this.convertArrayToString(finalData)
    this.walletEditor.setValue(convertedString)
    this.validatePrepareStep()
  }

  convertArrayToString(arr) {
    let s = ''
    arr.map((item, index) => {
      const breakLine = arr.length - 1 === index ? '' : '\n'
      if (item.isInvalidParam) s = s + `${item.text}${breakLine}`
      else s = s + `${item.address},${item.amount}${breakLine}`
    })
    return s
  }

  @action handleCombine() {
    this.validatePrepareStep()
    const validData = this.walletEditorData.filter(item => !item.isInvalidParam)
    const group = groupBy(validData, 'address')
    const tmp = {}
    Object.keys(group).map(key => {
      if (group[key].length > 1) {
        tmp[key] = group[key].reduce((a, b) => {
          if (a.isZero()) return b.amount
          else return a.addUnsafe(b.amount)
        }, Zero)
      }
    })
    const duplicateAddressArr = Object.keys(tmp)
    const filterCombinedData = this.walletEditorData.filter((item, index) => {
      if (item.isInvalidParam || !duplicateAddressArr.includes(item.address)) return true
      const firstIndex = this.walletEditorData.findIndex(f => f.address === item.address)
      if (firstIndex === index) return true
    })
    const finalData = filterCombinedData.map(item => {
      if (duplicateAddressArr.includes(item.address) && !item.isInvalidParam) item.amount = tmp[item.address]
      return item
    })
    const convertedString = this.convertArrayToString(finalData)
    this.walletEditor.setValue(convertedString)
    this.validatePrepareStep()
  }

  //confirm function
  @asyncAction *loadConfirmStep() {
    try {
      this.checkApproveLoading = true
      yield (this.estimateGas = yield this.handleEstimateGas())
      this.isApproved = yield this.multiclaimHandler!.approvedContract(walletStore.account, this.tokenInfo)
    } catch (e) {
      snackController.error(e.message || e.msg)
    } finally {
      this.checkApproveLoading = false
    }
  }

  @asyncAction *approve() {
    try {
      this.approveLoading = true
      yield this.multiclaimHandler!.approveContract(walletStore.account, this.tokenInfo)
      this.isApproved = yield this.multiclaimHandler!.approvedContract(walletStore.account, this.tokenInfo)
      yield (this.estimateGas = yield this.handleEstimateGas())
      if (this.isApproved) snackController.success('Approved successfully')
    } catch (e) {
      snackController.error(e.message || e.msg)
    } finally {
      this.approveLoading = false
    }
  }

  //sending function
  async handleSending() {
    this.transData = []
    const arr = chunk(this.walletEditorData, MAX_ACCOUNT_PER_TRANS)
    try {
      arr.map(async (item, index) => {
        const transactionInfo = this.convertArrayToString(item)
        const callback = ({ completed }) => {
          const trans = this.transData[index]
          if (trans) {
            trans.loading = false
            trans.state = completed
          } else {
            const data = [...this.transData, { loading: false, state: false, transactionInfo, displayCopyIcon: false }]
            this.changeTransData(data)
          }
        }
        const addresses = map(item, 'address')
        const values = map(item, 'amount')
        const res = await this.multiclaimHandler!.addWinnerList(
          walletStore.account,
          this.poolId,
          addresses,
          values,
          this.decimals,
          callback
        )
        const data = [
          ...this.transData,
          { hash: res, loading: true, state: false, transactionInfo, displayCopyIcon: false }
        ]
        this.changeTransData(data)
      })
    } catch (e) {
      console.error(e)
    }
  }

  @action onMouseEnter(index) {
    const data = this.transData.map((item: any, itemIndex) => {
      if (itemIndex === index) item.displayCopyIcon = true
      return item
    })
    this.transData = data
  }

  @action onMouseLeave(index) {
    const data = this.transData.map((item, itemIndex) => {
      if (itemIndex === index) item.displayCopyIcon = false
      return item
    })
    this.transData = data
  }

  @action getBscScanUrl(hashTrans) {
    const chainId = walletStore.chainId
    const validChainId = isNumber(+chainId) ? +chainId : chainId
    if (validChainId === 97) window.open(`https://bscscan.com/tx/${hashTrans}`)
    else if (validChainId === 56) window.open(`https://testnet.bscscan.com/tx/${hashTrans}`)
  }

  @action backToPool(router) {
    // router.replace(`/pools/${this.poolId}`)
  }

  @action copyHash(item, navigator) {
    navigator.clipboard.writeText(item.transactionInfo)
  }

  @action changeTransData(data: any) {
    this.transData = [...data] as any
  }

  @computed get decimals() {
    return this.tokenInfo?.decimals || 18
  }
  @computed get tokenInfo() {
    return this.pool?.tokenInfo
  }
  @computed get tokenName() {
    return this.tokenInfo?.symbol
  }
  @computed get coverUrl() {
    return this.pool?.coverUrl
  }
  @computed get avatarUrl() {
    return this.pool?.avatarUrl
  }
  @computed get poolName() {
    return this.pool?.name
  }

  //prepare
  @computed get errorCount() {
    return Object.keys(this.errorList).length
  }

  @computed get duplicateCount() {
    return Object.keys(this.duplicateList).length
  }

  //confirm
  @computed get isErrorConfirm() {
    return bnHelper.gt(this.totalAmount, this.tokenInfo?.balance || Zero)
  }

  //sending
  @computed get isCompletedSending() {
    return this.transData.length === this.transNumber && every(this.transData, ['loading', false])
  }
  @computed get isErrorSending() {
    return this.isCompletedSending && every(this.transData, ['state', false])
  }
}
