




















import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Watch } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import AddWinnerViewModel from '../viewmodels/add-winner-viewmodel'

@Observer
@Component({
  components: {
    Stepper: () => import('../components/stepper.vue')
  }
})
export default class Sender extends Vue {
  @Provide() vm = new AddWinnerViewModel()

  walletStore = walletStore

  @Watch('$route.params.id', { immediate: true }) changePoolId(value) {
    if (value) {
      this.vm.changePoolId(value)
    }
  }
}
